import React from 'react';
import ReactDOM from 'react-dom/client';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'
import { WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'
import App from './App';


const projectId = "f0bc9860fec99ad200511441adaad49a"
const chains = [bsc]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, appName: 'Web3Modal' })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <WagmiConfig config={wagmiConfig}>
    <App />
  </WagmiConfig>

);

