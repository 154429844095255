import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { readContract } from "../instances/instance";
import { useAccount } from "wagmi";
import { Table } from "react-bootstrap";
const HistoryDetail = () => {
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  console.log("id of snack", id);

  useEffect(() => {
    const fetchHistory = async () => {
      // console.log("options", id);
      try {
        const readContractInstance = readContract();
        const getHistory = await readContractInstance.getHistory(address, id);
        console.log("GRT HISTORY", getHistory);
        for (let index = 0; index <= getHistory._totallength; index++) {
          console.log("get history", getHistory[0][index][0]);
          // setUsers(getHistory[0][index][0]);
          // const history = Number(getHistory._totallength);
          setUsers((users) => {
            const newUsers = [...users];
            newUsers[index] = getHistory[0][index][0];
            return newUsers;
          });
        }
      } catch (error) {
        console.error("Failed to fetch pools data: ", error);
        // toast.error(
        //   "Failed to check withdrawable reward. Please try again later."
        // );
      }
    };
    fetchHistory();
  }, [address]);

  return (
    <div className="bg_pool" style={{height:'100vh'}}>
        {
            isConnected ? <div className="container margin">
            { id==1 ?  <h1 className="text_nfts line_none mb-3 text-center mt-lg-5 pt-lg-3">
             Direct <span className="hero_text">Snack # {id}</span>{" "}
            </h1> : 
            <h1 className="text_nfts line_none mb-3 text-center mt-lg-5 pt-lg-3">
           InDirect <span className="hero_text">Snack # {id}</span>{" "}
          </h1> 

               }
            {/* <h1 className="text-white mt-5">Wallet Address</h1> */}
            <div className="row">
              <div className="col-lg-3">
      
              </div>
            <div className="col-lg-6 mt-lg-5 mt-2 col-12">
              <div className="card p-3 table_height">
                <Table hover variant="transparent" className="table_text ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Wallet Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => {
                      return (
                        <>
                          <tr className=" align-items-center">
                            <td className="text-white">{index + 1}</td>
                         
      
                            <td className="text-white  truncate-text">{user}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-lg-3">
      
              </div>
            </div>
            
          </div> :
          <div className="cards p-lg-5 p-1 w-50 wid_mobile align-items-center  mx-auto text-center text-danger margin_extra text-center">
          <p className="text-center hero_text">
            Please Connect Wallet to View Pools
          </p>
        </div>

        }
    
    </div>
  );
};

export default HistoryDetail;
