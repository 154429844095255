import Nav from "react-bootstrap/Nav";
import React, { useEffect, useState } from "react";
import Pool from "./PoolBusd";
import PoolBnb from "./PoolBnb";
import { readContract, stakingRead } from "../instances/instance";
import { ethers, formatEther, parseEther } from "ethers";
import { toast } from "react-hot-toast";

function PoolTabs() {
  const [activeKey, setActiveKey] = useState("/home");
  const [balance,setBalance] = useState("")
  useEffect(() => {
    const fetchCoinPrice = async () => {
      try {
        let readContractInstance = readContract();
        let coinBalance = await readContractInstance.tokenPrice();
        // console.log(coinBalance, "raw coinBalance");
        let formattedBalance = formatEther(coinBalance.toString());
        // console.log(formattedBalance,"formattedBalance")
        setBalance(formattedBalance);
      } catch (error) {
        // console.error("Error while fetching coin price:", error);
        toast.error("Error while fetching coin price");
      }
    };
    fetchCoinPrice();
  },[]);
  return (
    <div className="container pt-0">
      <div className="row justify-content-center text-center">
        <h5
          className="text_nfts text-start line_none mt-3 mb-0"
          style={{ fontSize: "18px" }}
        >
          Coin <span className="hero_text2">Price:</span>{" "} <span className="ms-2">{balance} $</span>
        </h5>
        
        <p></p>
        <Nav
          variant="tabs"
          defaultActiveKey="/home"
          className="justify-content-center"
          onSelect={(selectedKey) => setActiveKey(selectedKey)} // Update activeKey state when a tab is clicked
        >
          <Nav.Item>
            <Nav.Link eventKey="/home" className="bg-transparent clr_bnb">
              BUSD
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="link-1" className="bg-transparent clr_bnb">
              BNB
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <div className="row justify-content-center text-center mt-3">
        {/* Conditionally render components based on activeKey */}
        {activeKey === "/home" && <Pool />}
        {activeKey === "link-1" && <PoolBnb />}
      </div>
    </div>
  );
}

export default PoolTabs;
