import React, { useState, useEffect } from "react";
import { readContract, stakingRead } from "../instances/instance.js";
import { contractAddress, spaceStaking } from "../instances/reffralAddress.js";
import spaceStakeAbis from "../instances/abis/spaceStakeAbis.json";
import { ethers, formatEther } from "ethers";
import { toast } from "react-hot-toast";
import { useAccount } from "wagmi";
import refferalAbis from "../instances/abis/refferalAbis.json";
import Table from "react-bootstrap/Table";
const Staking = () => {
  const [lockedTokens, setLockedTokens] = useState("0.0000");
  const [roi, setRoi] = useState("0.0000");
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [lockIds, setLockedIds] = useState("");
  const [unStake, setUnStake] = useState("");
  const [lockedPara, setLockedPara] = useState([]);
  const [tokenIds, setTokenIds] = useState([]);
  const [withdrawCheck, setwithdrawCheck] = useState(false);
  const [poolsData, setPoolsData] = useState([]);
  const [poolsDataWithdrawal, setPoolsDataWithdrawal] = useState([]);
  useEffect(() => {
    const fetchPoolData = async () => {
      try {
        const readContractInstance = stakingRead();
        let pool = [];
        const prices = await readContractInstance.getWithdrawedHistory(address);
        // console.log("prices of stakeingread",prices)
        // console.log("lengthhhhhhhh",prices.length())
        // prices.map((i)=>{
        // const priceFormatted = parseFloat(formatEther(i));
        //   if (priceFormatted > 0) {
        //         pool.push({
        //           id: i,
        //           price: priceFormatted
        //         });
        //       }
        // })
        for (let i = 0; i < prices.length; i++) {
          const priceFormatted = parseFloat(formatEther(prices[i]));
          const fourDigits = priceFormatted.toFixed(4);
          // console.log(fourDigits,"fourDigits")
          if (priceFormatted > 0) {
            pool.push({
              id: i,
              price: fourDigits,
            });
          }
        }
        // for (let i = 0; i <= 8; i++) {
        //   console.log("hsdaaskjdkasjdksajdkas",price)
        //   // Assuming you have a function to convert the price from a BigNumber
        //   const priceFormatted = parseFloat(formatEther(price));

        //   if (priceFormatted > 0) {
        //     pools.push({
        //       id: i,
        //       price: priceFormatted
        //     });
        //   }
        // }
        setPoolsDataWithdrawal(pool);
      } catch (error) {
        console.error("Failed to fetch pools data: ", error);
        // toast.error(
        //   "Failed to check withdrawable reward. Please try again later."
        // );
      }
    };
    fetchPoolData();
  }, [address]);
  useEffect(() => {
    const fetchPoolsData = async () => {
      try {
        const readContractInstance = readContract();
        let pools = [];
        for (let i = 1; i <= 8; i++) {
          const price = await readContractInstance.withdrawableReward(
            address,
            i
          );
          // Assuming you have a function to convert the price from a BigNumber
          const priceFormatted = parseFloat(formatEther(price));
          // console.log(priceFormatted, "kakajdkakdjaskjdaskj");
          if (priceFormatted > 0) {
            pools.push({
              id: i,
              price: priceFormatted,
            });
          }
        }
        setPoolsData(pools);
      } catch (error) {
        console.error("Failed to fetch pools data: ", error);
        // toast.error(
        //   "Failed to check withdrawable reward. Please try again later."
        // );
      }
    };
    fetchPoolsData();
  }, [address]);
  const handleLockIdsChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      if (inputValue > 0) {
        setLockedIds(inputValue);
      } else {
        // Set quantity to an empty string when inputValue is 0
        setLockedIds("");
      }
    } else {
      // Input is not a valid positive integer, set quantity to an empty string
      setLockedIds("");
    }
  };
  const handleUnStakeChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      if (inputValue > 0) {
        setUnStake(inputValue);
      } else {
        // Set quantity to an empty string when inputValue is 0
        setUnStake("");
      }
    } else {
      // Input is not a valid positive integer, set quantity to an empty string
      setUnStake("");
    }
  };
  useEffect(() => {
    const getUserLockedPoints = async () => {
      try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(
          spaceStaking,
          spaceStakeAbis,
          provider
        );
        // const readContractInstance = readContract();
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        // console.log(address, "address");
        const _tokens = await contract.getUserLockedTokens(address);
        if (!_tokens) {
          throw new Error("Failed to fetch _tokens");
        }
        let stakedTokensInEther = ethers.formatEther(_tokens);
        let _points = stakedTokensInEther.toString();
        // console.log("_points : ", _points);
        setLockedTokens(stakedTokensInEther); // Assuming stakedTokens is BigNumber
      } catch (error) {
        console.error(
          "An error occurred while fetching user locked points:",
          error
        );
      }
    };
    getUserLockedPoints();
  }, [lockIds]);
  const handleButtonClick = async () => {
    if (lockIds === "") {
      toast.error("Please enter your pool");
    }
    try {
      let provider = new ethers.BrowserProvider(window.ethereum);
      const contract = new ethers.Contract(
        spaceStaking,
        spaceStakeAbis,
        provider
      );
      const signer = await provider.getSigner();
      const address = await signer.getAddress();
      // console.log(address, "address");
      let stakedIds = await contract.getUserIds(address, lockIds);
      // console.log(stakedIds, "stakedIds");
      // Handle stakedIds...
      handleIds(stakedIds);
    } catch (error) {
      // console.error(
      //   "An error occurred while fetching user locked points:",
      //   error
      // );
      toast.error("Error");
    }
  };
  const handleIds = (stakedIds) => {
    // console.log("Received stakedIds:", stakedIds);
    setLockedPara(stakedIds.map((id) => id.toString()));
  };
  useEffect(() => {
    const getUserRoi = async () => {
      try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(
          spaceStaking,
          spaceStakeAbis,
          provider
        );
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        // console.log(address, "address");
        const contractRoi = new ethers.Contract(
          contractAddress,
          refferalAbis,
          provider
        );
        let withdrawNFT = await contractRoi.getWithdrawable(address);
        // console.log(withdrawNFT, "withdrawNFT");
        let stakedRoi = await contract.userROI(address);
        let AddRoi = withdrawNFT + stakedRoi;
        let stakedRoiInEther = ethers.formatEther(AddRoi);
        let fixedRoi = parseFloat(stakedRoiInEther).toFixed(4);
        // console.log(fixedRoi, "fixedRoi");
        setRoi(fixedRoi);
        
      } catch (error) {
        console.error(
          "An error occurred while fetching user locked points:",
          error
        );
      }
    };
    getUserRoi();
    const updateTimeoutId = setTimeout(() => {
      getUserRoi();
    }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  
    // Clean up the timeout on component unmount
    return () => clearTimeout(updateTimeoutId);
   
  }, []);
  const handleUnStake = async () => {
    let toastId;
    // if(tokenIds===""){
    //   toast.error("please enter quantity")
    // }
    try {
      if (!tokenIds || tokenIds.trim() === "") {
        toast.error("You do not have a token ID");
        return;
      }
      let provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      let address = await signer.getAddress();
      const ContractReadOnly = new ethers.Contract(
        spaceStaking,
        spaceStakeAbis,
        provider
      );
      const canUnStake = await ContractReadOnly.canUnstake();
      // console.log("Directly from contract:", canUnStake);
      if (canUnStake) {
        const tokenIdsArray = tokenIds.split(",").map(Number);
        if (tokenIdsArray.length === 0) {
          toast.error("You do not have a valid token ID");
          return;
        }
        // console.log("tokenIdsArray", tokenIdsArray);
        // Ensuring that the contract instance is connected with a signer for state-changing methods.
        const contractWithSigner = new ethers.Contract(
          spaceStaking,
          spaceStakeAbis,
          signer
        );
        // Notify the user that the unstaking process has started.
        const pendingToastId = toast.loading("Unstaking in progress...");
        let transactionResponse = await contractWithSigner.unstakeNFTs(
          address,
          unStake,
          tokenIdsArray
        );
        let provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(
          spaceStaking,
          spaceStakeAbis,
          provider
        );
        const _tokens = await contract.getUserLockedTokens(address);
        if (!_tokens) {
          throw new Error("Failed to fetch _tokens");
        }
        let stakedTokensInEther = ethers.formatEther(_tokens);
        let _points = stakedTokensInEther.toString();
        // console.log("_points : ", _points);
        // Wait for the transaction to be mined
        let transactionReceipt = await transactionResponse.wait();
        // Dismiss the pending toast.
        // toast.dismiss(pendingToastId);
        if (transactionReceipt.status === 1) {
          toast.dismiss(toastId);
          toast.success("Unstake Successful");
          // console.log("Transaction mined: ", transactionReceipt);
          setLockedTokens(stakedTokensInEther);
          window.location.reload();
        } else {
          toast.error("Unstake failed.");
        }
      } else {
        toast.error("Unstake time is not over");
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.error("An error occurred while unstaking NFTs: ", error);
    }
  };
  const withdraw = async () => {
    let toastId;
    try {
      let provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      let address = await signer.getAddress();
      // Ensuring that the contract instance is connected with a signer for state-changing methods.
      const contractWithSigner = new ethers.Contract(
        spaceStaking,
        spaceStakeAbis,
        signer
      );
      const withdrawTime = await contractWithSigner.canWithdraw(address);
      // console.log("withdrawTime",withdrawTime);
      let currentTime = Math.floor(Date.now() / 1000);
      // console.log("currentTime",currentTime)
      if (withdrawTime <= currentTime) {
        let toastId = toast.loading("Withdrawal Pending..");
        const withdraw = await contractWithSigner.withdrawROI();
        await withdraw.wait();
        toast.dismiss(toastId);
        toast.success("Successfull Withdrawal");
        window.location.reload();
      } else {
        toast.error("Wait for 24 hours");
      }
    } catch (error) {
      console.error("An error occurred while Withdraw: ", error);
      toast.dismiss(toastId); // Close any pending toast
      // toast.error("Failed to approve tokens");
      if (error.code && error.code === 4001) {
        toast.error("User rejected the transaction.");
      } else {
        toast.error("Your withdrawal is too low");
      }
    }
  };
  return (
    <div className="bg_pool" style={{ height: isConnected ? "auto" : "100vh" }}>
      <div className="container">
        {isConnected ? (
          <>
            <div className="container margin_extra">
              <h1 className="text_nfts line_none mt-3 mb-3 text-center mt-lg-5 pt-lg-5">
                Stak<span className="hero_text">ing</span>{" "}
              </h1>
              {/* <h1 className="colr text-center">Staking</h1> */}
              <div className="col-lg-12">
                <div className="row mt-5">
                  <div className="col-lg-6 mb-3" data-aos="fade-up">
                    <div className="card p-3">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <h3 className="text_nfts fs-3 line_none text-center ">
                          Locked <span className="hero_text">Tokens</span>{" "}
                        </h3>
                        <p className="mb-0 text-white text-end">
                          {lockedTokens}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3" data-aos="fade-up">
                    <div className="card  p-3">
                      <div className="d-flex w-100 justify-content-between align-items-center">
                        <h3 className="text_nfts fs-3 line_none text-center ">
                          User <span className="hero_text">FOC</span>{" "}
                          <span className="place">XPACE TOKEN</span>
                        </h3>
                        <p className="mb-0 text-white">{roi}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 mb-3"
                    data-aos="fade-up"
                    data-aos-offset="300"
                    data-aos-easing="ease-in-sine"
                  >
                    <div className="card p-3">
                      <h3 className="text_nfts fs-3 line_none text-center ">
                        Locked <span className="hero_text">Ids</span>{" "}
                      </h3>
                      <input
                        type="number"
                        placeholder="Pool (1-8)"
                        className="p-2 w-100  bg-transparent border text-white mb-3 mt-4 rounded"
                        value={lockIds}
                        onChange={handleLockIdsChange}
                      />
                      <button
                        className="button-191 mt-1 mb-5"
                        onClick={handleButtonClick}
                      >
                        <span>Check</span>
                      </button>
                      {lockIds ? (
                        Array.isArray(lockedPara) && lockedPara.length > 0 ? (
                          <ul
                            className="d-flex gap-3 px-0 mt-2 text-white text-decoration-none"
                            style={{ listStyle: "none" }}
                          >
                            ID:
                            {lockedPara.map((id, index) => (
                              <li key={index}>{id}</li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-white">
                            No IDs available or loading...
                          </p>
                        )
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6 mb-3" data-aos="fade-up">
                    <div className="card p-3">
                      <h3 className="text_nfts fs-3 line_none text-center ">
                        Un<span className="hero_text">stake</span>{" "}
                      </h3>
                      <input
                        type="number"
                        placeholder="Pool (1-8)"
                        className="p-2 w-100  bg-transparent border text-white mb-3 mt-2 rounded"
                        value={unStake}
                        onChange={handleUnStakeChange}
                      />
                      <input
                        type="text"
                        placeholder="Token Ids like 1,33,2000"
                        className="p-2 w-100  bg-transparent border text-white mb-2 mt-2 rounded"
                        value={tokenIds}
                        onChange={(e) => setTokenIds(e.target.value)}
                      />
                      <div className="d-flex justify-content-between align-items-center">
                        <button
                          className="button-191 mt-2"
                          onClick={handleUnStake}
                        >
                          <span>Unstake</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    className="button-191 fw-bolder mb-5 mt-5 mx-auto"
                    onClick={withdraw}
                  >
                    <span>Withdraw</span>
                  </button>
                  {withdrawCheck === true ? (
                    <p className="text-success mb-0 text-center">
                      Withdraw Successfull
                    </p>
                  ) : (
                    ""
                  )}
                  {/* withdraw hiostry */}
                  <div className="row">
                    <div className="col-lg-6 mb-3" data-aos="fade-up">
                      <div className="card  p-3">
                        <div className="w-100 justify-content-between align-items-center">
                          {/* <h3>User ROI</h3> */}
                          <h3 className="text_nfts fs-3 line_none text-center ">
                            Withdrawable{" "}
                            <span className="hero_text">History</span>{" "}
                          </h3>
                          <div style={{ height: "200px", overflow: "auto" }}>
                            <Table
                              hover
                              variant="transparent"
                              className="table_text"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Snacks ID</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {poolsData.map((pool) => (
                                  <tr key={pool.id}>
                                    <td>{pool.id}</td>
                                    <td>{`Snack ${pool.id}`}</td>
                                    <td>{pool.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-3" data-aos="fade-up">
                      <div className="card  p-3">
                        <div className="w-100 justify-content-between align-items-center">
                          <h3 className="text_nfts fs-3 line_none text-center ">
                            Withdrawed{" "}
                            <span className="hero_text">History</span>{" "}
                          </h3>
                          <div style={{ height: "200px", overflow: "auto" }}>
                            <Table
                              hover
                              variant="transparent"
                              className="table_text"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Snacks</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {poolsDataWithdrawal.map((poolsval) => (
                                  <tr key={poolsval.id}>
                                    <td>{poolsval.id + 1}</td>
                                    <td>{`Snacks ${poolsval.id + 1}`}</td>
                                    <td>{poolsval.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="cards p-lg-5 p-1 w-50 wid_mobile align-items-center  mx-auto text-center text-danger margin_extra text-center">
            <p className="text-center hero_text">
              Please Connect Wallet to View Staking Page
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default Staking;
