import React, { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { spaceStaking } from "../instances/reffralAddress.js";
import spaceStakeAbis from "../instances/abis/spaceStakeAbis.json";
const UserNfts = () => {
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [newImageLinks, setImageLinks] = useState([]);
  // Pool names
  const poolNames = {
    1: "Snack# 1",
    2: "Snack# 2",
    3: "Snack# 3",
    4: "Snack# 4",
    5: "Snack# 5",
    6: "Snack# 6",
    7: "Snack# 7",
    8: "Snack# 8",
    // Add names for all your pools
  };
  useEffect(() => {
    const checkUserIds = async () => {
      try {
        let provider = new ethers.BrowserProvider(window.ethereum);
        const contract = new ethers.Contract(
          spaceStaking,
          spaceStakeAbis,
          provider
        );
        const signer = await provider.getSigner();
        const userAddress = await signer.getAddress();
        // Define the number of pools you have
        const numPools = 8;
        let newImageLinks = [];
        for (let i = 1; i <= numPools; i++) {
          const stakedId = await contract.getUserIds(userAddress, i);
          if (stakedId.length > 0) {
            // Map each stakedId to an image link
            for (const id of stakedId) {
              const imageLink = `https://gateway.pinata.cloud/ipfs/QmczeakgapnjDdhcHVN2ceH3GEz1cLzAbFUk3bUqwSHBuu/${id}.png`;
              newImageLinks.push({
                poolName: poolNames[i],
                imageLink: imageLink,
                id: `${id}`,
              });
            }
          }
        }
        // setNftId(stakedId);
        setImageLinks(newImageLinks);
      } catch (error) {
        console.error(
          "An error occurred while fetching user locked points:",
          error
        );
      }
    };
    if (isConnected) {
      checkUserIds();
    }
  }, [isConnected, address]);
  return (
    <div className="bg_pool" style={{ height: isConnected && newImageLinks > 0   ? "auto" : "100vh" }}>
      <div className="margin">
        {isConnected ? (
          <>
            <div className="container">
              <div className="row pt-5 pb-5">
                <h1 className="text_nfts line_none mb-3 text-center mt-lg-1 pt-lg-1">
                  Your Purchased <span className="hero_text">Nft's</span>{" "}
                </h1>
                {newImageLinks.map((item, index) => (
                  <div key={index} className="col-md-4 col-lg-3  mt-3 mb-3">
                    
                    <div className="cards" data-aos="fade-up">
                      <img
                        src={item.imageLink}
                        alt={`NFT ${index + 1}`}
                        className="p-2 img-fluid"
                        style={{ borderRadius: "18px" }}
                      />
                       {/* {index === 0 || item.poolName !== newImageLinks[index - 1].poolName ? (
                        <p className="text_nfts text-center">
                          {item.poolName} NFTs
                        </p>
                      ) : null} */}
                      <div className="d-flex pool_bg1 mb-2 justify-content-between ">
                      <h6 className="fs-5 fw-bold mb-0">{item.poolName}</h6>
                      <h6 className="fs-5 mb-0 fw-bold"> ID# {item.id}</h6>
                      </div>                      
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="cards p-lg-5 p-1 w-50 wid_mobile align-items-center  mx-auto text-center text-danger margin_extra text-center">
            <p className="text-center hero_text">
              Please Connect Wallet to View Purchased NFT's
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserNfts;
