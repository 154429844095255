import React, { useEffect, useState } from "react";
import PoolTabs from "./PoolTabs";
import { ethers } from "ethers";
import { writeContract, readContract } from "../instances/instance";
import { useAccount, useNetwork } from "wagmi";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
const Reffral = () => {
  const location = useLocation();
  const { state } = location;
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [reffral, setReffral] = useState("");
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const { refCode } = useParams();
  const { chain } = useNetwork();
  const bscTestnetChainId = 56;
  // const bscTestnetChainId = 97;

  // console.log(refCode,"useparams");
  useEffect(() => {
    const checkUserRegistration = async () => {
      try {
        const readContractInstance = readContract();
        const { referrer } = await readContractInstance.userInfo(address);
        setIsUserRegistered(
          referrer !== "0x0000000000000000000000000000000000000000"
        );
      } catch (error) {
        console.error("Failed to check registration status: ", error);
      }
    };
    if (isConnected && chain?.id === bscTestnetChainId) {
      checkUserRegistration();
    }
    // Set up a polling mechanism to check user registration every 30 seconds
    const intervalId = setInterval(() => {
      if (isConnected) {
        checkUserRegistration();
      }
    }, 30000);
    // Perform cleanup: clear the polling interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [address, isConnected, chain]);

  // useEffect(() => {
  //   const getDefaultReferral = async () => {
  //     try {
  //       let readContractInstance = readContract();
  //       const defaultReferral = await readContractInstance.defaultRefer();
  //       console.log("Default referral from contract:", defaultReferral);
  //       setReffral(defaultReferral);
  //       localStorage.setItem("defaultReferral", defaultReferral);
  //     } catch (error) {
  //       console.error("Failed to fetch the default referral: ", error);
  //     }
  //   };

  //   // This will run only once to set the referral based on localStorage or fetch it
  //   const storedReferral = localStorage.getItem("defaultReferral");
  //   const storedRefReferral = localStorage.getItem("refCode");
  //   console.log("Retrieved from local storage:", storedReferral, storedRefReferral);

  //   // Using if-else-if to make sure only one condition runs
  //   if (storedRefReferral) {
  //     setReffral(storedRefReferral);
  //   } else if (storedReferral) {
  //     setReffral(storedReferral);
  //   } else if (!isConnected) {
  //     console.log("Wallet is NOT connected, fetching default referral.");
  //     getDefaultReferral();
  //   }
  //   // We do not add reffral or isConnected to the dependencies to avoid re-fetching unnecessarily
  // }, []);

  useEffect(() => {
    const getDefaultReferral = async () => {
      try {
        if (refCode) {
          setReffral(refCode);
          localStorage.setItem("refCode", refCode);
          //  console.log(refCode,"refCodekjqwkjekqwjekqwjkeqwj")
        } else {
          // console.log("Inside else block");
          let readContractInstance = readContract();
          const defaultReferral = await readContractInstance.defaultRefer();
          // console.log("Default referral from contract:", defaultReferral);
          setReffral(defaultReferral);
          localStorage.setItem("defaultReferral", defaultReferral);
        }
      } catch (error) {
        console.error("Failed to fetch the default referral: ", error);
      }
    };
    if (!reffral) {
      if (isConnected) {
        // console.log("Wallet is connected.");
        // If additional logic is required when the wallet is connected, add it here.
      } else {
        // console.log("Wallet is NOT connected, fetching default referral.");
        getDefaultReferral();
      }
    }
  }, [isConnected, reffral]);
  useEffect(() => {
    const storedReferral = localStorage.getItem("defaultReferral");
    // console.log("Retrieved from local storage:", storedReferral);
    const storedRefReferralCode = localStorage.getItem("refCode");
    // console.log("ref code jbdjasbdjas",storedRefReferralCode);
    if (storedReferral && !refCode) {
      setReffral(storedReferral);
    } else {
      setReffral(refCode);
    }
    // if (storedRefReferral && !reffral) {
    //   setReffral(refCode);
    // }
  }, []);
  // If that didn't work, fetch the default referral.
  // Note the empty dependency array. This ensures the effect only runs once.
  // console.log("Reffral state value:", reffral);
  const refferalUser = async () => {
    let toastId; // Declare toastId here
    try {
      if (!reffral) {
        toast.error("error: Referral address is not defined");
        return;
      }
      const referralCode = reffral.split("/").pop();
      let readContractInstance = readContract();
      const defaultReferral = await readContractInstance.defaultRefer();
      // console.log(defaultReferral, "defaultReferral");
      let provider = new ethers.BrowserProvider(window.ethereum);
      let signer = await provider.getSigner();
      const { referrer } = await readContractInstance.userInfo(signer);
      // console.log("referrer", referrer);
      if (referrer !== "0x0000000000000000000000000000000000000000") {
        // console.log("User already registered");
        return;
      } else {
        const writeContractInstance = await writeContract();
        toastId = toast.loading("Transaction sent. Pending confirmation..."); // Assign toastId here
        const trxResponse = await writeContractInstance.register(referralCode);
        const trxReceipt = await trxResponse.wait();
        if (trxReceipt.status === 1) {
          // console.log(
          //   "Transaction confirmed. Hash:",
          //   trxReceipt.transactionHash
          // );
          setIsUserRegistered(true);
          toast.dismiss(toastId); // Close the previous toast
          toast.success("Registration confirmed!");
        } else {
          // Transaction failed
          console.error("Transaction failed");
          toast.dismiss(toastId); // Close the previous toast
          toast.error("Transaction failed!");
        }
      }
    } catch (error) {
      toast.dismiss(toastId);
      toast.error("Transaction error: ", error);
      if (error.code && error.code === 4001) {
        toast.error("User rejected the transaction.");
      } else {
        toast.error("Transaction failed.");
      }
    }
  };
  return (
    <>
      {state && state.error && (
        <div className="error-message">{state.error}</div>
      )}
      <div
        className="bg_pool"
        style={{ height: isConnected ? "auto" : "100vh" }}
      >
        {isConnected ? (
          <div className="bg_pool2">
            <div className="container margin pt-5 mb-5">
              <div className="row justify-content-center">
                <div className="col-lg-12 mx-auto justify-content-center">
                  <div
                    className=""
                    style={{
                      height:
                        isUserRegistered && isConnected ? "auto" : "100vh",
                    }}
                  >
                    {isUserRegistered && isConnected ? null : (
                      <>
                        <div className="card justify-content-center align-items-center text-center">
                          <h1 className="text_nfts line_none mt-5 mb-5 text-center">
                            En<span className="hero_text">list</span>{" "}
                          </h1>
                          <div className="col-lg-12 d-flex flex-column align-items-center mt-4 justify-content-center">
                            <input
                              type="text"
                              placeholder="Refferal Code"
                              className="bg-transparent border w-75 small rounded text-white p-3 outline-0"
                              value={reffral}
                              onChange={(e) => setReffral(e.target.value)}
                            />
                            <button
                              className="button-191 mt-lg-5   mt-5 mb-5"
                              onClick={refferalUser}
                              disabled={!isConnected}
                            >
                              <span>Enlist</span>
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {isUserRegistered && isConnected ? <PoolTabs /> : ""}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="cards p-lg-5 p-1 w-50 wid_mobile align-items-center  mx-auto text-center text-danger margin_extra text-center">
            <p className="text-center hero_text">
              Please Connect Wallet to View Pools
            </p>
          </div>
        )}
      </div>
    </>
  );
};
export default Reffral;
