import React from "react";

const Terms = () => {
  return (
    <div className="container pt-5 mb-5 pb-5 margin">
      <div className="row">
        <div className="col-lg-12 align_p">
          <h1 className="text_nfts text-center mb-5">
            Terms & <span className="hero_text">Conditions</span>{" "}
          </h1>
          <p className="hero_text_p mt-3">
            While the XPACE Coin has released a roadmap outlining future goals
            and plans for community development, we cannot guarantee to
            accomplish every item outlined during the pre-launch planning phase
            as ideas and projects evolve organically. You agree that your
            purchase of a XPACE Tokens from our initial launch of XPACEs Coin is
            all you are guaranteed to receive with your initial purchase,
            whether through primary or secondary channels. Any future benefits
            are ancillary to this purchase and not to be taken into
            consideration with your initial purchase. You agree that you are not
            relying on any future commitments by The XPACE Coin in participating
            in our XPACE Token launch.
          </p>
          <p className="hero_text_p mt-3">
            XPACE Tokens were created purely as collectibles, not as investment
            vehicles or substitutes for cryptocurrency. We make absolutely no
            promise or guarantee that these XPACEs will subsequently retain
            monetary value in fiat, cash or cryptocurrency.
          </p>
          <p className="hero_text_p mt-3">
            The sale or use of XPACEs or virtual coins could be prohibited under
            applicable securities law. It is possible that existing regulations
            could be applied, or new regulations could be enacted, affecting
            blockchain technology-based applications and sales of XPACE tokens
            or virtual coins such that any aspect of XPACE projects could be
            negatively affected, requiring its modification or discontinuance
            and potentially resulting in the loss of tokens or token value.
          </p>
          <p className="hero_text_p mt-3">
            In an effort to always be as compliant as possible with new
            practices, The XPACE Coin reserves the right to adapt and adjust
            these disclaimers, practices, promises and roadmap at any time it
            deems necessary.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Terms;
