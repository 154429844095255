// BSC Mainnet

export const contractAddress = "0x5d8bfc8bCA203A65eceeC9328df5d96135b2dd31";
export const spaceStaking = "0xc9eA9CcEbfEA3E1ea63B2Ba78A4649929d9024C5";
export const spaceToken = "0x44eeD22a5702d37dcdcF3f910116d8B912055993";
export const busdToken = "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56";

// BSC Testnet
// export const contractAddress = "0x0cdB702C5c73A1E1fE927F3e3E664c9bcb9110Ab";
// export const spaceStaking = "0x22A4cc8De9B7E0b93c733c0faBCa1AF3FAC353cD";
// export const spaceToken = "0x16168b26C2EDCaAA044F4D67cC0454Fe0EfEA4d6";
// export const busdToken = "0x33D54e2E33C5a1BBBbBdd51A6668af4dC4465ff8";


// DXB CHAIN

// export const contractAddress = "0x44aF865425ecD0BF7F455F4d3b66637eB7B7d2c5";
// export const spaceStaking = "0x496CFB6D4e7d1eB2C65773f1C2002271b8cCFB7b";
// export const spaceToken = "0x0948310BeeDad6514Ccac06023B41056DB989e19";
// export const busdToken = "0x68804d1543758c9D397Cd713F79e0A302189219d";

// export const contractAddress = "0x1C2f4d7a54E0680c1D3Ac5DC9aA9e640a0A399AC";
// export const spaceStaking = "0xc8056a44000c326E157Abe9bc22396a8d8daE499";
// export const spaceToken = "0xC21c7B24E3232356b7956fF1Cd0ebFdC4a9220b3";
// export const busdToken = "0x495FCDa16130b58B53d5d0080952CFE16797394B";
