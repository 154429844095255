import React from "react";
import hero_img from "../../assets/herosectionbg.png";
import { BsArrowRight } from "react-icons/bs";
import { NavLink } from "react-router-dom";
const Herosection = () => {
  return (
    <div className="margin ">
      <div className="bg_img py-2">
        <div className="container p-3  ">
          <div className="row mb-5">
            <div className="hero__object circle"></div>
            <div
              className="col-lg-8 col-12 mobile"
              style={{ position: "relative", left: "0" }}
            >
              <div className="content-wrapper">
                <h1 className="hero_text_font text-white" data-aos="fade-up">
                  <span className="hero_text" title="XPACE NFT">
                    XPACE NFT{" "}
                  </span>
                  <br /> Collectibles
                </h1>
                <p className="hero_text_p" data-aos="fade-up">
                  All XPACE NFTs in one Place. Connect with us for newest
                  feature <br /> releases, NFT announcements, drops and tips &
                  tricks.
                </p>
                <div className="d-lg-flex d-block gap-5 mt-5 align-items-center">
                  <NavLink to="/referral" className="text-decoration-none">
                    <button
                      className="button-191 btn_shadow mb-4 mb-lg-0 mobile_btn"
                      data-aos="fade-up"
                    >
                      <span>Mint NFTs</span>
                    </button>
                  </NavLink>
                  {/* <NavLink to="/usernfts" className="text-decoration-none">
             <button className='bg-transparent border-0 outline-0'><a className='btn_explore' data-aos="fade-up">Explore Collections <BsArrowRight className='ms-2 fs-5'/></a></button> 
                
                </NavLink> */}
                  <NavLink
                    to="/usernfts"
                    className="btn_explore text-decoration-none"
                    data-aos="fade-up"
                  >
                    Explore Collections <BsArrowRight className="ms-2 fs-5" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              {/* <img src={hero_img} alt="" className='  d-lg-block d-none w-100' /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container p-3">
        <div className="row mt-5 pt-5">
          <div className="col-md-6 my-md-3 my-lg-0 col-lg-3" data-aos="fade-up">
            <div className="box">
              <h2>24.000 +</h2>
              <p>DIGITAL ART</p>
            </div>
          </div>
          <div
            className="col-md-6 my-md-3 my-lg-0  col-lg-3"
            data-aos="fade-up"
          >
            <div className="box">
              <h2>16.000 +</h2>
              <p>ARTISTS</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3" data-aos="fade-up">
            <div className="box">
              <h2>48.000 +</h2>
              <p>SALES</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3" data-aos="fade-up">
            <div className="box">
              <h2>98.000 +</h2>
              <p>COMMUNITY MEMBERS</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Herosection;
