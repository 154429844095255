import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { NavLink, useLocation } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useNetwork } from "wagmi";
import spacex from "../assets/golden logo.png";
import { readContract } from "../instances/instance";
import "../index.css";
import WhitePaper from "../assets/whitepaper.pdf";
import document from "../assets/E-DOCUMENTS 2.pdf";

// import { Web3Button } from '@web3modal/react'
// const nav= ["Home", "Register", "Staking", "Nft's"]
const nav = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Enlist",
    url: "/referral",
  },
  {
    name: "Staking",
    url: "/staking",
  },
  {
    name: "Nft's",
    url: "/usernfts",
  },
  {
    name: "History",
    url: "/history",
  },
];
const Navigat = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [hasReferral, setHasReferral] = useState(false);
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  let { pathname } = useLocation();
  const { chain } = useNetwork();
  const bscTestnetChainId = 56;
  // const bscTestnetChainId = 56;

  // console.log(pathname); // For example: '/about'
  const formatAddress = (address) => {
    return address
      ? `${address.substring(0, 5)}.......${address.substring(
          address.length - 3
        )}`
      : "";
  };
  useEffect(() => {
    const checkReferral = async () => {
      if (isConnected && chain?.id === bscTestnetChainId) {
        const readContractInstance = readContract();
        const { referrer } = await readContractInstance.userInfo(address);
        setHasReferral(
          referrer !== "0x0000000000000000000000000000000000000000"
        );
      }
      // setIsLoading(false); // Move this outside the if condition
    };
    checkReferral();
  }, [isConnected, address, chain]);

  const walletHandler = (_url) => {
    // console.log(`Navigating to: ${_url}, referral status: ${hasReferral}`);
    // console.log(_url.toString() === "/staking");
    if (_url.toString() === "/staking") {
      if (hasReferral) {
        navigate("/staking");
      } else {
        // toast.error("Please add a referral first");
        navigate("/staking");
      }
    } else {
      navigate(_url);
    }
  };
  return (
    <div className="navbar-container">
      <nav className="app__navbar mt-lg-4 mt-0">
        <div className="app__navbar-logo">
          <NavLink to={"/"}>
            <h1 className="text-dark">
              <img src={spacex} className="img-fluid logo" />
            </h1>
          </NavLink>
        </div>
        <ul className="app__navbar-links mt-lg-3 justify-content-end pe-3">
          {nav?.map(({ name, url }, ind) => (
            <p
              className="mb-0"
              onClick={() => walletHandler(url)}
              key={ind}
              style={{
                fontWeight: pathname === url ? "bold" : 400,
                color: "#fff",
              }}
            >
              <li className="p__opensans">{name}</li>
            </p>
          ))}

          <div className="dropdown ">
            <a
              className="bg-transparent text-white ms-2 dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Documents
            </a>

            <ul
              className="dropdown-menu pt-3"
              aria-labelledby="dropdownMenuLink"
              style={{ background: "black" }}
            >
              <li className="mb-3">
                <a
                  href={WhitePaper}
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Whitepaper
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={document}
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Document
                </a>
              </li>
            </ul>
          </div>
        </ul>
        <Nav className="md_btn">
          <button className="button-191" onClick={() => open()}>
            <span className="">
              {isConnected
                ? chain?.id === bscTestnetChainId
                  ? formatAddress(address)
                  : "Wrong Network"
                  : "Connect wallet"}
            </span>
          </button>
          <Nav.Link></Nav.Link>
        </Nav>
        <div className="app__navbar-smallscreen align-items-center">
          <button className="button-191" onClick={() => open()}>
            <span>
              {isConnected
                ? chain?.id === bscTestnetChainId
                  ? formatAddress(address)
                  : "Wrong Network"
                  : "Connect"}
            </span>
          </button>

          <CgMenuLeft
            className="mt-1 ms-3"
            color="#fff"
            cursor="pointer"
            fontSize={27}
            onClick={() => setToggleMenu(true)}
          />
          {toggleMenu && (
            <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <AiOutlineClose
                fontSize={27}
                className="overlay__close"
                onClick={() => setToggleMenu(false)}
              />
              <ul className="app__navbar-smallscreen_links">
                <NavLink to={"/"}>
                  <li>
                    <a onClick={() => setToggleMenu(false)}>Home</a>
                  </li>
                </NavLink>
                <NavLink to={"/referral"}>
                  <li>
                    <a onClick={() => setToggleMenu(false)}>Enlist</a>
                  </li>
                </NavLink>
                <NavLink to={"/staking"}>
                  <li>
                    <a onClick={() => setToggleMenu(false)}>Staking</a>
                  </li>
                </NavLink>
                <NavLink to={"/usernfts"}>
                  <li className="p__opensans">
                    <a onClick={() => setToggleMenu(false)}>Nft's</a>
                  </li>
                </NavLink>
                <NavLink to={"/history"}>
                  <li className="p__opensans">
                    <a onClick={() => setToggleMenu(false)}>History</a>
                  </li>
                </NavLink>
                <div className="dropdown ">
                  <a
                    class="bg-transparent text-white ms-2 dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Documents
                  </a>

                  <ul
                    className="dropdown-menu pt-3"
                    aria-labelledby="dropdownMenuLink"
                    style={{ background: "black" }}
                  >
                    <li className="mb-3">
                      <a
                        href={WhitePaper}
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Whitepaper
                      </a>
                    </li>
                    <li className="mb-3">
                      <a
                        href={document}
                        target="_blank"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        E-Document
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navigat;
