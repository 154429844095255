import React from "react";
import about from "../../assets/Xcoinstart.png";
const Coinstart = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-lg-12 d-lg-flex d-block gap-4 align-items-center">
          <div className="col-lg-6 mt-5 pt-5" data-aos="fade-up">
            <h1 className="text_nfts">
              When Did XPACE <br />{" "}
              <span className="hero_text">Coin Start</span>{" "}
            </h1>
            <p className="mt-3 about_p">
              XPACEcoin was born in the midst of one of the busiest times in US
              history. With no regulatory body monitoring its transactions or
              acknowledging it as legal tender like USD (United States Dollar),
              EURO (euro), and GBP Sterling sterlings, this cryptocurrency was
              created as a means of restoring trust among users amid the climate
              of suspicion created by the global financial crisis toward banks
              and central governments. In 2021,an unidentified person (or
              group), created XPACEcoin. June 20th was the creation date of
              XPACEcoin's.
            </p>
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <div className="img_about_us">
              <img src={about} alt="" className="img-fluid " />
              {/* <div class="meet__object circle_about"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coinstart;
