import "./App.css";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import Referral from "./components/Reffral";
import ProtectedStaking from "./components/Staking";
import Navigat from "./components/Navigate";
import Home from "../src/Pages/Home";
import Terms from "./components/Terms";
import UserNfts from "./components/UserNfts";
import { useAccount,useNetwork} from "wagmi";
import { readContract } from "./instances/instance";
import History from "./components/History";
import HistoryDetail from "./components/HistoryDetail";
function App() {
  const { address, isConnected } = useAccount();
  const [hasRef, setHasRef] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const { chain } = useNetwork();
  const bscTestnetChainId = 56; 
  // const bscTestnetChainId = 97; 


  useEffect(() => {
    const checkReferral = async () => {
      if (isConnected && chain?.id === bscTestnetChainId) {
        setIsLoading(true); // Start loading only if on the correct network
  
        try {
          const readContractInstance = readContract();
          const { referrer } = await readContractInstance.userInfo(address);
          setHasRef(referrer !== "0x0000000000000000000000000000000000000000");
        } catch (error) {
          console.error("Error checking referral:", error);
          toast.error("An error occurred while checking referral.");
        }
  
        setIsLoading(false); // Stop loading after the check
      }
    };
  
    checkReferral();
  }, [isConnected, address, chain]);
  
  // function PrivateRoute({ children }) {
  //   if (isLoading) {
  //     return <div>Loading...</div>;
  //   }
  
  //   if (!hasRef) {
  //     toast.error("Please add a referral first");
  //     return <Navigate to="/referral" replace />;
  //   }
  
  //   // Add this check
  //   if (chain?.id !== bscTestnetChainId) {
  //     toast.error("Please switch to the BSC Testnet");
  //     return <Navigate to="/" replace />; // Redirect them to home or another page
  //   }
  
  //   return children;
  // }
  
  return (
    <div className="">
      <Toaster position="bottom-center" reverseOrder={false} />
      <Router>
        <Navigat />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/referral/:refCode" element={<Referral />} />
          <Route
            path="/staking"
            element={
              // <PrivateRoute>
                <ProtectedStaking />
              // </PrivateRoute>
            }
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/usernfts" element={<UserNfts />} />
          <Route path="/history" element={<History />} />
          <Route path="/history/:id" element={<HistoryDetail />} />


        </Routes>
      </Router>
    </div>
  );
}

export default App;
