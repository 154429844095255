import React, { useEffect, useState } from "react";
import { readContract } from "../instances/instance";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

const History = () => {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const [userRoi, setUserRoi] = useState([])
    const [totalUsers, setTotalUsers] = useState(new Array(8).fill(0));
    console.log("totalUsers", totalUsers);
    const navigate = useNavigate();

    const options = [
        {
            id: 1,
            name: "Direct Snack 1",
            value: 15,
            pool: 1,
        },
        {
            id: 2,
            name: "InDirect Snack 2",
            value: 8,
            pool: 2,
        },
        {
            id: 3,
            name: "InDirect Snack 3",
            value: 7,
            pool: 3,
        },
        {
            id: 4,
            name: "InDirect Snack 4",
            value: 5,
            pool: 4,
        },
        {
            id: 5,
            name: "InDirect Snack 5",
            value: 5,
            pool: 5,
        },
        {
            id: 6,
            name: "InDirect Snack 6",
            value: 5,
            pool: 6,
        },
        {
            id: 7,
            name: "InDirect Snack 7",
            value: 3,
            pool: 7,
        },
        {
            id: 8,
            name: "InDirect Snack 8",
            value: 2,
            pool: 8,
        },
    ];
    useEffect(() => {
        const fetchHistory = async () => {
            // console.log("options", id);
            try {
                const readContractInstance = readContract();

                for (let index = 1; index <= options.length; index++) {
                    const getHistory = await readContractInstance.getHistory(address, index);
                    console.log("get history",getHistory);
                    const history = Number(getHistory._totallength);
                    console.log("history of ddsfds",history);
                    setTotalUsers(users => {
                        const newUsers = [...users];
                        newUsers[index - 1] = history;
                        return newUsers;
                    });
                }
            } catch (error) {
                console.error("Failed to fetch pools data: ", error);
                // toast.error(
                //   "Failed to check withdrawable reward. Please try again later."
                // );
            }
        };
        fetchHistory();
    }, [address]);
    return (
        <div className="bg_pool" style={{height:'100vh'}}>
{
    isConnected ? 
        <div className="container margin">
            <div className="">
                <h1 className="text_nfts line_none mb-3 text-center mt-lg-5 pt-lg-3">
                    His<span className="hero_text">tory</span>{" "}
                </h1>
                <div className="container">
                    <div className="row mt-5">
                        {options?.map((option, index) => (
                            <div className="col-lg-4 mb-4" key={option.id} onClick={(()=>navigate(`${option.id}`))} style={{cursor:'pointer'}}>
                                <div className="card p-3">
                                    <h5 className="text-white">{option.name}</h5>
                                    <div className="d-flex justify-content-between w-100 align-items-center">
                                        <h6 className="text-white mb-0">Enlisted Users</h6>
                                        <p className="text-white mb-0 fw-bold">{totalUsers[index]}</p>
                                    </div>
                                    <div className="d-flex justify-content-between w-100 align-items-center">
                                        <h6 className="text-white mb-0">FOC</h6>
                                        <p className="text-white mb-0 fw-bold">{totalUsers[index] * option.value}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        :
        <div className="cards p-lg-5 p-1 w-50 wid_mobile align-items-center  mx-auto text-center text-danger margin_extra text-center">
        <p className="text-center hero_text">
          Please Connect Wallet to View Pools
        </p>
      </div>    

}
        </div>
    );
};

export default History;
