import React from "react";
import wolf1 from "../../assets/nftecosystem.png";
const Roadmap = () => {
  return (
    <div className="container mt-5 pt-lg-5 mb-5">
      <div className="row">
        <div className="col-lg-12 d-lg-flex d-block gap-4 align-items-center">
          <div className="col-lg-6 mt-5" data-aos="fade-up">
            <p className="nft_p mb-0">Roadmap of XPACE NFT</p>
            <h1 className="text_nfts">
              Build the infrastructure of the NFT ecosystem{" "}
              <span className="hero_text">NFT ecosystem</span>{" "}
            </h1>
            <p className="mt-3 about_p">
              XPACE NFT adopts a highly integrated and heavily invested
              infrastructure to ensure ERC/XPC compatibility and building
              supporting facilities, which gives it first-mover advantages and
              strong competitiveness. In this regard, XPACE NFT can take up the
              best resources of the industry and expand the influence of the
              organization. NFT artworks will be made into unique ERC/XPC tokens
              on-chain. The tokens will be stored in the ERC/XPC smart contracts
              of NFT tokens, and the rights and interests of the underlying
              artworks belong to NFT holders. Corresponding information of the
              ERC/XPC token and the records of the artwork will be permanently
              stored on the BTFS network (BitTorrent File System) while files
              will be permanently stored on the Internet.
            </p>
          </div>
          <div
            className="col-lg-6 mb-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="2000"
          >
            <div className="img_about_us justify-content-end d-flex">
              <img src={wolf1} alt="" className="img-fluid " />
              {/* <div class="meet__object circle_about"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
