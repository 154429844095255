import React from "react";
import wolf from "../../assets/nftstrength.png";
const Advance = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row mt-5 pt-5">
        <div className="col-lg-12 d-lg-flex d-block gap-4 align-items-center">
          <div className="col-lg-6 mb-5" data-aos="fade-up">
            <div className="img_about_us">
              <img src={wolf} alt="" className="img-fluid  " />
              {/* <div class="meet__object circle_about"></div> */}
            </div>
          </div>
          <div className="col-lg-6 " data-aos="fade-up">
            <p className="nft_p mb-0">Advanced technology </p>
            <h1 className="text_nfts">
              Strengths of <span className="hero_text">XPACE NFT</span>{" "}
            </h1>
            <p className="mt-3 about_p">
              XPACE NFT is backed by the underlying technology of top-notch
              blockchains BUSD and BNB with support from the world's largest
              distributed storage system Bittorrent File System (BTFS) to
              deliver the mission of registering world-class artworks as NFTs on
              the blockchain. NFT artworks will be made into unique BUSD/BNB
              tokens on-chain. The tokens will be stored in the BUSD/BNB smart
              contracts of NFT tokens, and the rights and interests of the
              underlying artworks belong to NFT holders. Corresponding
              information of the ERC/XPC token and the records of the artwork
              will be permanently stored on the BTFS network (BitTorrent File
              System) while files will be be permanently stored.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advance;
