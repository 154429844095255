import React, { useState, useEffect } from "react";
import { ethers, formatEther } from "ethers";
import { busdToken, contractAddress } from "../instances/reffralAddress";
import busdAbis from "../instances/abis/busdAbis.json";
import refferalAbis from "../instances/abis/refferalAbis.json";
import { parseEther } from "viem";
import { getWalletBalance, readContract } from "../instances/instance";
import { toast } from "react-hot-toast";
import { useAccount, useChainId } from "wagmi";
import imgpool from "../assets/Explorenft1.png";
const PoolItem = ({ id, name, value, pool, busdBalance, userPoints }) => {
  const [quantity, setQuantity] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  // Define the styles for the overlay
  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(10px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };
  // Define the styles for the image
  const imageStyle = {
    maxWidth: "300px",
    maxHeight: "300px",
  };
  const handleQuantityChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      if (inputValue > 0) {
        setQuantity(inputValue);
      } else {
        setQuantity("");
      }
    } else {
      setQuantity("");
    }
  };
  const handlePurchase = async () => {
    let toastId;
    if (quantity === "") {
      toast.error("Please enter quantity");
      return;
    }
    // console.log(`Purchase initiated for PoolItem with id: ${id}`);
    try {
      if (quantity !== "0") {
        setIsProcessing(true);
        let provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const nftInstance = new ethers.Contract(
          contractAddress,
          refferalAbis,
          signer
        );
        const bnbPrice = await nftInstance.getBNBPrice(id, quantity);
        let { 0:_totalPrice } = bnbPrice;
        // console.log("bnbPrice", parseFloat(formatEther(_totalPrice)));
        // console.log("busdBalance", parseFloat(busdBalance));
        const bnbPriceString = bnbPrice.toString();
        // console.log("bnbPriceString <= parseEther(busdBalance)", parseFloat(formatEther(_totalPrice)) <= parseFloat(busdBalance));
        let remainingSupply = await nftInstance.getRemainingLevelSupply(id);
        // console.log(remainingSupply, "remainingSupply");
        if (remainingSupply >= quantity) {
          if (parseFloat(formatEther(_totalPrice)) <= parseFloat(busdBalance)) {
            const coinPrice = await nftInstance.priceCheck(pool, quantity);
            const desiredValue = coinPrice[1];
            let points = parseEther(userPoints);
            if (desiredValue <= points) {
              toastId = toast.loading("Approving transaction...");
              const transaction = await nftInstance.Mint_BNB(id, quantity, {
                value: _totalPrice,
              });
              // Notify user the transaction is pending.
              // Now, wait for the transaction to be mined.
              const receipt = await transaction.wait();
              // Check if the transaction was successful.
              if (receipt.status === 1) {
                setIsProcessing(false);
                toast.dismiss(toastId);
                window.location.reload();
                toast.success("Successful Transaction");
                // console.log(transaction, "Transaction details");
              } else {
                toast.dismiss(toastId);
                toast.error("Transaction failed.");
              }
            } else {
              toast.error("You don't have enough points");
            }
            // toastId = toast.loading("Approving transaction...");
            // This initiates the transaction, but it's still in a "Pending" state.
          } else {
            toast.error("You have not enough balance or points");
            setIsProcessing(false);
          }
        } else {
          toast.error("Supply Exceeded.Please try next snack");
        }
      }
    } catch (error) {
      toast.dismiss(toastId);
      console.error("Error during purchase:", error);
      // setIsProcessing(false);
      setIsProcessing(false);
      if (error.code && error.code === 4001) {
        setIsProcessing(false);
        toast.error("User rejected the transaction.");
      } else {
        toast.error("User rejected the transaction");
      }
      // toast.error("You have not enough balance or points", error);
    }
  };
  return (
    <>
      {isProcessing && (
        <div style={overlayStyle}>
          <div className="cards">
            <img src={imgpool} alt="Processing" style={imageStyle} />
          </div>
        </div>
      )}
      <div className="col-lg-6 mb-lg-3 mb-1 p-lg-2 p-2" data-aos="fade-up">
        <div className="card p-3 text-dark mb-3 ">
          <h3 className="text_nfts line_none mt-3 mb-0 fs-3">
            {name} <span className="hero_text">{pool}</span>
          </h3>
          <h5 className="text_nfts line_none mt-3 mb-3 fs-3 text-start">
            ${value} per <span className="hero_text">NFT</span>{" "}
          </h5>
          <input
            type="number"
            placeholder="Quantity"
            className="w-100 p-2 bg-transparent border text-white mb-3 mt-3 rounded"
            value={quantity}
            onChange={handleQuantityChange}
          />
          <button className="button-191 mt-2" onClick={handlePurchase}>
            <span>Purchase</span>
          </button>
        </div>
      </div>
    </>
  );
};
const PoolBnb = () => {
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [userPoints, setUserPoints] = useState(null);
  const [busdBalance, setBusdBalance] = useState(null);
  useEffect(() => {
    const checkUserRegistration = async () => {
      try {
        const readContractInstance = readContract();
        const { referrer, points } = await readContractInstance.userInfo(
          address
        );
        // console.log(points, "points");
        setIsUserRegistered(
          referrer !== "0x0000000000000000000000000000000000000000"
        );
        setUserPoints(ethers.formatEther(points));
        // console.log(userPoints, "User Points After Setting");
      } catch (error) {
        console.error("Failed to check registration status: ", error);
      }
    };
    if (isConnected) {
      checkUserRegistration();
    }
    // If ethereum is injected by MetaMask or another dApp browser,
    // set up event listeners
    const intervalId = setInterval(checkUserRegistration, 1000); // Poll every 30 seconds
    const handleAccountChanged = (accounts) => {
      // console.log("Account changed to:", accounts[0]);
      // Refetch user registration
      checkUserRegistration();
    };
    const handleChainChanged = (chainId) => {
      // console.log("Chain changed to:", chainId);
      // Refetch user registration
      checkUserRegistration();
    };
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", handleAccountChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }
    // Perform cleanup: remove the event listeners and clear the polling interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountChanged);
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [address, isConnected]);
  useEffect(() => {
    const fetchBusdBalance = async () => {
      // Check if Web3 is injected by a browser extension like MetaMask
      if (typeof window.ethereum !== "undefined") {
        try {
          let provider = new ethers.BrowserProvider(window.ethereum);
          const contract = new ethers.Contract(busdToken, busdAbis, provider);
          const signer = await provider.getSigner();
          const address = await signer.getAddress();
          const balance = await getWalletBalance(address);
          // console.log("Raw balance:", balance.toString());
          let balanceInWei = ethers.parseUnits(balance.toString(), 18);
          let formatBalance = ethers.formatEther(balanceInWei);
          let displayBalance = parseFloat(formatBalance).toFixed(4);
          // console.log("Formatted balance:", displayBalance);
          setBusdBalance(displayBalance);
        } catch (err) {
          console.error(err);
        }
      } else {
        // console.log("Ethereum object does not exist!");
      }
    };
    fetchBusdBalance();
    const handleAccountChanged = (accounts) => {
      // console.log("Account changed to:", accounts[0]);
      // Refetch balance
      fetchBusdBalance();
    };
    // Listen for chain (network) changes and refetch balance on change
    const handleChainChanged = (chainId) => {
      // console.log("Chain changed to:", chainId);
      // Refetch balance
      fetchBusdBalance();
    };
    // If ethereum is injected by MetaMask or another dApp browser,
    // set up event listeners
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", handleAccountChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }
    // Perform cleanup: remove the event listeners when the component is unmounted
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountChanged);
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);
  const options = [
    {
      id: 1,
      name: "Snack",
      value: 10,
      pool: 1,
    },
    {
      id: 2,
      name: "Snack",
      value: 20,
      pool: 2,
    },
    {
      id: 3,
      name: "Snack",
      value: 50,
      pool: 3,
    },
    {
      id: 4,
      name: "Snack",
      value: 100,
      pool: 4,
    },
    {
      id: 5,
      name: "Snack",
      value: 500,
      pool: 5,
    },
    {
      id: 6,
      name: "Snack",
      value: 1000,
      pool: 6,
    },
    {
      id: 7,
      name: "Snack",
      value: 2000,
      pool: 7,
    },
    {
      id: 8,
      name: "Snack",
      value: 5000,
      pool: 8,
    },
  ];
  return (
    <div className="container p-0">
      <h1 className="text_nfts line_none mt-3 mb-3">
        Deposit <span className="hero_text2">Snacks</span>{" "}
      </h1>
      <div className="col-lg-12 col-12 mt-1 mb-3">
        <div className="row gx-0">
          <h5 className="text_nfts line_none fs-5">
            BNB: <span className="hero_text">{busdBalance}</span>
          </h5>
          {isUserRegistered && isConnected ? (
            <h5 className="text_nfts line_none fs-5">
              <span className="hero_text">Coins:</span> {userPoints}
            </h5>
          ) : (
            <h5 className="text_nfts line_none fs-5">
              <span className="hero_text">Coins:</span> 0
            </h5>
          )}
          {options.map((option) => {
            return (
              <PoolItem
                key={option.id}
                {...option}
                busdBalance={busdBalance}
                userPoints={userPoints}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default PoolBnb;
