import React from "react";
import nft1 from "../../assets/Explorenft1.png";
import nft2 from "../../assets/Explorenft2.png";
import nft3 from "../../assets/Explorenft3.png";
import nft4 from "../../assets/Explorenft4.png";
import nft5 from "../../assets/Explorenft5.png";
import nft6 from "../../assets/Explorenft6.png";
import nft7 from "../../assets/Explorenft7.png";
import nft8 from "../../assets/Explorenft8.png";
const ExploreNfts = () => {
  return (
    <div className="bg_nfts">
      <div className="container mt-5 pt-5">
        <div className="row g-3">
          <p className="nft_p mb-0">COLLECTIONS</p>
          <h1 className="text_nfts">
            Explore <span className="hero_text">NFTs</span>{" "}
          </h1>
          <div className="col-md-4 col-lg-3  custom-gap">
            <div className="cards" data-aos="fade-up">
              <img src={nft1} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3  pool_bg">Snack 1</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft2} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 2</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft3} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 3</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft4} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 4</h5>
            </div>
          </div>
        </div>
        <div className="row g-3 mt-3">
          <div className="col-md-4 col-lg-3  custom-gap">
            <div className="cards" data-aos="fade-up">
              <img src={nft5} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3  pool_bg">Snack 5</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft6} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 6</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft7} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 7</h5>
            </div>
          </div>
          <div className="col-md-4 col-lg-3  custom-gap" data-aos="fade-up">
            <div className="cards">
              <img src={nft8} alt="" className="img-fluid" />
              <h5 className="mt-3 mb-3 pool_bg">Snack 8</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreNfts;
