import React from "react";
import circulaion from "../../assets/nftcirculation.png";
const Circulaion = () => {
  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-lg-12 d-lg-flex d-block gap-4 align-items-center">
          <div className="col-lg-6" data-aos="fade-up">
            <div className="img_about_us">
              <img src={circulaion} alt="" className="img-fluid" />
              {/* <div class="meet__object circle_about"></div> */}
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <h1 className="text_nfts">
              Achieve a strong{" "}
              <span className="hero_text">circulation of NFT</span>{" "}
            </h1>
            <p className="mt-3 about_p">
              XPACE NFT is going to list its token -- NFT on leading exchanges
              and conduct quick promotion in communities to allow more users to
              enjoy the benefits of XPACE NFT's growth. It is also going to
              utilize voting and mining as a way to facilitate democratic,
              pluralistic governance. Use cases of NFT include Governance token:
              NFT holders will have the right to decide on the management of
              XPACE NFT's collections, the potential acquisition, on-chain
              distribution and exhibition of artworks, as well as the right to
              decide on the exhibits way, and duration of exhibitions in the
              cyberpunk online virtual museum; DeFi airdrop and mining: The
              token NFT will participate in the growth of DeFi ecosystems such
              as the ones of Ethereum and XPACE, and will also join hands with
              HECO and BSC to launch relevant DeFi airdrop and mining;
              Democratic voting: NFT holders will have the right to vote on the
              future direction of collections, including but not limited to the
              style, genre, age, author and form of artworks, and to approve the
              method and amount of support provided; Online governance rewards:
              NFT will be used to reward holders of BTC, ETH,TRX and other
              tokens who participate in XPACE NFT. The governance rewards will
              be distributed as appropriate to expand the coverage of the
              project and interact with other ecosystems.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Circulaion;
