import React from "react";

const Mission = () => {
  return (
    <div className="container mb-5 pt-5">
      <div className="row">
        <div className="col-lg-3 custom-gap d-flex" data-aos="fade-up">
          <div className="cards_mission p-3 flex-column align-items-start">
            <div className="">
              <h1 className="text_nftss">
                VIS<span className="hero_text">ION</span>
              </h1>
            </div>
            <div>
              <p className="about_p mt-2">
                XPACE NFT is aimed at becoming the ARK Funds in the NFT space to
                build a bridge between top-notch artists and blockchain, and to
                support the growth of native crypto NFT artists.
              </p>
            </div>
          </div>
        </div>
        <div
          className="col-lg-3 custom-gap  justify-content-start d-flex"
          data-aos="fade-up"
        >
          <div className="cards_mission p-3 flex-column">
            <h1 className=" text_nftss">
              MISS<span className="hero_text">ION</span>
            </h1>
            <p className=" about_p mt-2">
              Xpace is dedicated to preserving and promoting world-class
              artworks by registering them as Non-Fungible Tokens (NFTs) on the
              blockchain. Our mission is to bridge the traditional art world
              with the emerging digital frontier, ensuring that the value,
              ownership, and cultural significance of these artworks are
              safeguarded and accessible to a global audience.
            </p>
          </div>
        </div>
        <div
          className="col-lg-3 custom-gap  justify-content-start  d-flex"
          data-aos="fade-up"
        >
          <div className="cards_mission p-3 flex-column">
            <h1 className=" text_nftss">
              XPACE NFT's <br /> <span className="hero_text">Collection</span>
            </h1>
            <p className="about_p mt-2">
              Currently available at deeply discounted pricing, our many NFT
              Collections will soon be priced in high bucks. You own it,
              purchase it, keep it, and take advantage of XPace's profits.
            </p>
          </div>
        </div>
        <div
          className="col-lg-3 custom-gap  justify-content-start  d-flex"
          data-aos="fade-up"
        >
          <div className="cards_mission p-3 flex-column">
            <h1 className=" text_nftss">
              Enrich the businesses of{" "}
              <span className="hero_text">XPACE NFT</span>
            </h1>
            <p className="about_p mt-2">
              To push the boundaries of the XPACE NFT space, expand "friend
              circles" and enrich its business, XPACE NFT will promote the
              creation and re-creation of top-class artworks and established
              franchise and custom NFT works with A-list celebrities with
              massive followings from the entertainment circles
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
