import React from 'react'
import Herosection from '../components/Home/Herosection'
import ExploreNfts from '../components/Home/ExploreNfts'
import AboutUs from '../components/Home/AboutUs'
import Mission from '../components/Home/Mission'
import Roadmap from '../components/Home/Roadmap'
import Circulaion from '../components/Home/Circulation'
import Coinstart from '../components/Home/Coinstart'
import Advance from '../components/Home/Advance'
import Footer from '../components/Footer'
const Home = () => {
  return (
    <div>
      <Herosection />
      <ExploreNfts />
      <AboutUs />
      <Mission />
      <Roadmap />
      <Circulaion />
      <Coinstart />
      <Advance />
      <Footer />

    </div>
  )
}

export default Home
