import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaXTwitter } from "react-icons/fa6";
import { MdOutlineFacebook } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";


const Footer = () => {
  return (
    <div className='container'>
      <div className='row gx-0'>
        <div className='col-lg-12 justify-content-between d-lg-flex d-block p-3 border_footer'>
             <p className='nft_p mb-0'>Copyright ©2023 <span className='hero_text'>All rights reserved.</span> </p>
           <NavLink to="terms">
           <p className='nft_p mb-0 mt-lg-0 mt-3'>Terms and <span className='hero_text'>conditions</span> 
           </p>
           </NavLink>
        </div>
        <div className='col-lg-12 justify-content-lg-end justify-content-start mt-0 pt-0 d-lg-flex p-3 d-flex gap-3'>
          <a href="https://twitter.com/PaceNft93176">
          <FaXTwitter className='text-white fs-3'/>
          </a>
          <a href="https://instagram.com/xpacenft95?igshid=OGQ5ZDc2ODk2ZA==">
          <FaInstagram className='text-white fs-3'/>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61550904620481&mibextid=ZbWKwL">
          <MdOutlineFacebook className='text-white fs-3'/>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
