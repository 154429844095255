import { Contract, ethers } from "ethers";
import contractAbi from "./abis/refferalAbis.json";
import { contractAddress, spaceStaking } from "./reffralAddress";
import spaceStakeAbis from "../instances/abis/spaceStakeAbis.json";
import { formatEther } from "viem";
// import { rpcURL } from "../config";

// export const readRemoteContract = () => {
//     try {
//         const provider = new ethers.JsonRpcProvider(rpcURL);
//         // let signer = await provider.getSigner();
//         return new Contract(contractAddress, contractAbi, provider);
//     } catch (error) {
//         console.error("error while read remote contract", error);
//     }
// }
export const readContract = () => {
  try {
    let provider = new ethers.BrowserProvider(window.ethereum);
    return new Contract(contractAddress, contractAbi, provider);
  } catch (error) {
    console.error("errro while factory instance", error);
  }
};
export const stakingRead = () => {
  try {
    let provider = new ethers.BrowserProvider(window.ethereum);
    return new Contract(spaceStaking, spaceStakeAbis, provider);
  } catch (error) {
    console.error("errro while factory instance", error);
  }
};
export const writeContract = async () => {
  try {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    let contractAdd = new Contract(contractAddress, contractAbi, signer);
    return contractAdd;
  } catch (error) {
    console.error("errro while factory instance", error);
  }
};
export const getWalletBalance = async (address) => {
  try {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let balance = await provider.getBalance(address);
    return formatEther(balance);
  } catch (error) {
    console.error("error while get wallet balance", error);
  }
};
