import React from "react";
import about from "../../assets/Coinneon.png";
const AboutUs = () => {
  return (
    <div className="container mt-lg-5 pt-lg-5 mt-0 mb-5">
      <div className="row">
        <div className="col-lg-12 d-lg-flex d-block gap-4">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <div className="img_about_us">
              <img src={about} alt="" className="img-fluid about_img" />
              <div className="meet__object circle_about"></div>
            </div>
          </div>
          <div className="col-lg-6" data-aos="fade-up">
            <h1 className="text_nfts">
              About <span className="hero_text">XPACE COIN</span>{" "}
            </h1>
            <p className="mt-3 about_p">
              XPACE NFT is a one-of-a-kind digital collectible that represents a
              journey through space and time. This unique NFT artwork was
              meticulously crafted by a digital artist, capturing the essence of
              cosmic exploration and the vastness of the universe Core
              businesses of XPACE NFT include: Investing in top NFT platforms
              and artworks, incubating leading NFT artists to build a bridge
              between world-class artists and the NFT world; sponsoring
              galleries, organizing art exhibitions or publications, and setting
              up awards to support art creation and criticism; establishing art
              collections, etc. By applying blockchain technology into the NFT
              world, XPACE NFT expands the use cases of blockchain. XPACE NFT
              purchases well-known traditional and NFT artworks as underlying
              assets, and works to improve the NFT trading standard. It provides
              support to government agencies, universities, lawyers and industry
              elites in issuing policies that govern the healthy development of
              the industry. There are now three major use cases in the
              blockchain industry, namely value exchange, collaboration, and
              record-keeping. The unique NFT fits in all three of the use cases.
              It is not only a hot trend in the blockchain industry, but also a
              critical element that is very likely to revolutionize the industry
              and take it to the next level. As a blockchain enterprise, XPACE
              NFT will invest in artworks and promote traditional and digital
              art by leveraging its strengths in technology, market, industry
              and policy research, with the aim of introducing blockchain into
              deserved use cases.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
