import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { busdToken, contractAddress } from "../instances/reffralAddress";
import busdAbis from "../instances/abis/busdAbis.json";
import refferalAbis from "../instances/abis/refferalAbis.json";
import { parseEther } from "viem";
import { readContract } from "../instances/instance";
import { useAccount } from "wagmi";
import { toast } from "react-hot-toast";
import imgpool from "../assets/Explorenft1.png";
import { MdContentCopy } from "react-icons/md"; // Import the copy icon
const PoolItem = ({
  id,
  name,
  value,
  pool,
  busdBalance,
  userPoints,
  imageUrl,
}) => {
  // console.log(setRefLink,"setRefLinkahdjashdjash")
  const handleQuantityChange = (e) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      if (inputValue > 0) {
        setQuantity(inputValue);
      } else {
        // Set quantity to an empty string when inputValue is 0
        setQuantity("");
      }
    } else {
      // Input is not a valid positive integer, set quantity to an empty string
      setQuantity("");
    }
  };
  // console.log(busdBalance, "busdBalance");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  // Define the styles for the overlay
  const overlayStyle = {
    position: "fixed", // Use 'fixed' to cover the entire screen
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(10px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };
  // Define the styles for the image
  const imageStyle = {
    maxWidth: "300px",
    maxHeight: "300px",
  };
  useEffect(() => {
    // When quantity changes, calculate and update the price
    const calculatedPrice = (quantity * value).toString();
    setPrice(calculatedPrice);
  }, [quantity, value]);
  const handlePurchase = async () => {
    let toastId; // Declare toastId here for managing toasts
    // console.log("busdBalance", busdBalance);
    // console.log(`Purchase initiated for PoolItem with id: ${id}`);
    // console.log(`Quantity: ${quantity}, Total Price: ${price}`);
    if (quantity === "") {
      toast.error("Please enter quantity");
      return; // Exit the function to prevent further execution
    }
    try {
      if (quantity !== "0") {
        setIsProcessing(true);
        let provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        // console.log("userAdressddadasdas",userAdress)
        const writeContractInstance = new ethers.Contract(
          busdToken,
          busdAbis,
          signer
        );
        const nftInstance = new ethers.Contract(
          contractAddress,
          refferalAbis,
          signer
        );
        let remainingSupply = await nftInstance.getRemainingLevelSupply(id);
        // console.log(remainingSupply, "remainingSupply");
        // let totalSupply = await nftInstance.getLevelSupply(id);
        // console.log(totalSupply,"totalSupplytotalSupply");
        // console.log("price", price);
        // console.log("busdBalance", busdBalance);
        if (remainingSupply >= quantity) {
          if (parseEther(price) <= parseEther(busdBalance)) {
            const coinPrice = await nftInstance.priceCheck(pool, quantity);
            const desiredValue = coinPrice[1];
            let points = parseEther(userPoints);
            if (desiredValue <= points) {
              // console.log(desiredValue.toString(), "desiredValue");
              // Notify user of transaction being initiated
              toastId = toast.loading("Approving transaction...");
              const tx = await writeContractInstance.approve(
                contractAddress,
                parseEther(price)
              );
              const receipt = await tx.wait();
              // console.log(`Transaction hash: ${tx.hash}`);
              // console.log(
              //   "Transaction was mined in block",
              //   receipt.blockNumber
              // );
              // Check the transaction receipt status
              if (receipt.status === 1) {
                // setTransactionCompleted(true);
                // Transaction succeeded
                toast.dismiss(toastId);
                toast.success(`Contract approved`);
                // Notify user that the minting process has started
                toastId = toast.loading("Minting in process...");
                let mintBusd = await nftInstance.Mint_BUSD(
                  id,
                  quantity,
                  parseEther(price)
                );
                let { hash } = await mintBusd.wait();
                // setRefLink(address)
                setIsProcessing(false);
                toast.dismiss(toastId);
                toast.success("Success");
                window.location.reload();
              } else {
                // setTransactionCompleted(false);
                // Transaction failed
                toast.dismiss(toastId);
                toast.error("Approval transaction failed!");
                setIsProcessing(false);
              }
            } else {
              toast.error("You don't have enough points (10 points for 1$)");
              setIsProcessing(false);
            }
          } else {
            toast.error("Your Balance is Low");
            setIsProcessing(false);
          }
        } else {
          toast.error("Supply Exceeded.Please try next snack");
          setIsProcessing(false);
        }
      }
    } catch (error) {
      toast.dismiss(toastId); // Close any pending toast
      console.error("Error occurred:", error);
      setIsProcessing(false);
      toast.error("Failed to approve tokens");
      if (error.code && error.code === 4001) {
        setIsProcessing(false);
        toast.error("User rejected the transaction.");
      } else {
        toast.error("User rejected the transaction");
      }
    }
  };
  return (
    <>
      {isProcessing && (
        <div style={overlayStyle}>
          <div className="cards">
            <img src={imgpool} alt="Processing" style={imageStyle} />
            {/* <h5 className='mt-3 mb-3 pool_bg'>Pool 2</h5>   */}
          </div>
          {/* <p>Please wait, processing...</p> */}
        </div>
      )}
      <div className="col-lg-6 mb-lg-3 mb-1 p-lg-2 p-2" data-aos="fade-up">
        <div className="card p-3 text-dark mb-3">
          <h3 className="text_nfts line_none mt-3 mb-0 fs-3">
            {name} <span className="hero_text">{pool}</span>
          </h3>
          <h5 className="text_nfts line_none mt-3 mb-3 fs-3 text-start">
            ${value} per <span className="hero_text">NFT</span>{" "}
          </h5>
          <input
            type="number"
            placeholder="Quantity"
            className="w-100 p-2 bg-transparent border text-white mb-3 rounded"
            value={quantity}
            onChange={handleQuantityChange}
          />
          <input
            type="number"
            placeholder="0.0"
            className="w-100 p-2 bg-transparent border text-white rounded"
            value={price}
            readOnly
          />
          <button className="button-191 mt-4" onClick={handlePurchase}>
            <span>Purchase</span>
          </button>
        </div>
      </div>
    </>
  );
};
const Pool = () => {
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  const [busdBalance, setBusdBalance] = useState(null);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [userPoints, setUserPoints] = useState(null);
  const [refLink, setRefLink] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  // console.log("userAdressddadasdas", address)
  useEffect(() => {
    const fetchBusdBalance = async () => {
      // Check if Web3 is injected by a browser extension like MetaMask
      if (typeof window.ethereum !== "undefined") {
        try {
          let provider = new ethers.BrowserProvider(window.ethereum);
          const contract = new ethers.Contract(busdToken, busdAbis, provider);
          const signer = await provider.getSigner();
          const address = await signer.getAddress();
          const balance = await contract.balanceOf(address);
          // console.log("balance", balance);
          setBusdBalance(ethers.formatUnits(balance, 18));
        } catch (err) {
          toast.error("Error");
          // console.error(err);
        }
      } else {
        // console.log("Ethereum object does not exist!");
      }
    };
    fetchBusdBalance();
    const handleAccountChanged = (accounts) => {
      // console.log("Account changed to:", accounts[0]);
      // Refetch balance
      fetchBusdBalance();
    };
    // Listen for chain (network) changes and refetch balance on change
    const handleChainChanged = (chainId) => {
      // console.log("Chain changed to:", chainId);
      // Refetch balance
      fetchBusdBalance();
    };
    // If ethereum is injected by MetaMask or another dApp browser,
    // set up event listeners
    const intervalId = setInterval(fetchBusdBalance, 30000);
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", handleAccountChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }
    // Perform cleanup: remove the event listeners and clear the polling interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountChanged);
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, []);
  useEffect(() => {
    const checkUserRegistration = async () => {
      try {
        const readContractInstance = readContract();
        const { referrer, points, totalDeposit } =
          await readContractInstance.userInfo(address);
        // console.log(points, "points");
        setIsUserRegistered(
          referrer !== "0x0000000000000000000000000000000000000000"
        );
        setUserPoints(ethers.formatEther(points));

        if (totalDeposit > 0) {
          setRefLink(address);
        }

        // console.log(userPoints, "User Points After Setting");
      } catch (error) {
        console.error("Failed to check registration status: ", error);
      }
    };
    if (isConnected) {
      checkUserRegistration();
    }
    // If ethereum is injected by MetaMask or another dApp browser,
    // set up event listeners
    const intervalId = setInterval(checkUserRegistration, 1000); // Poll every 30 seconds
    const handleAccountChanged = (accounts, totalDeposit) => {
      // console.log("Account changed to:", accounts[0]);
      // Refetch user registration
      checkUserRegistration();
      if (totalDeposit > 0) {
        setRefLink("");
      }
    };
    const handleChainChanged = (chainId) => {
      // console.log("Chain changed to:", chainId);
      // Refetch user registration
      checkUserRegistration();
    };
    if (typeof window.ethereum !== "undefined") {
      window.ethereum.on("accountsChanged", handleAccountChanged);
      window.ethereum.on("chainChanged", handleChainChanged);
    }
    // Perform cleanup: remove the event listeners and clear the polling interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", handleAccountChanged);
        window.ethereum.removeListener("chainChanged", handleChainChanged);
      }
    };
  }, [address, isConnected]);
  const options = [
    {
      id: 1,
      name: "Snack",
      value: 10,
      pool: 1,
    },
    {
      id: 2,
      name: "Snack",
      value: 20,
      pool: 2,
    },
    {
      id: 3,
      name: "Snack",
      value: 50,
      pool: 3,
    },
    {
      id: 4,
      name: "Snack",
      value: 100,
      pool: 4,
    },
    {
      id: 5,
      name: "Snack",
      value: 500,
      pool: 5,
    },
    {
      id: 6,
      name: "Snack",
      value: 1000,
      pool: 6,
    },
    {
      id: 7,
      name: "Snack",
      value: 2000,
      pool: 7,
    },
    {
      id: 8,
      name: "Snack",
      value: 5000,
      pool: 8,
    },
  ];
  const copyToClipboard = async (e) => {
    e.preventDefault();
    const referralUrl = `https://xpacecoin.com/referral/${refLink}`;
    try {
      await navigator.clipboard.writeText(referralUrl);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
    setTimeout(() => {
      setCopySuccess("");
    }, 2000);
  };
  return (
    <div className="container p-0">
      <h1 className="text_nfts line_none mt-3 mb-3">
        Deposit <span className="hero_text2">Snacks</span>{" "}
      </h1>
      {/* <h1 className="colr text-center mt-1 mb-1"> </h1> */}
      <div className="col-lg-12 col-12 mt-1 mb-3">
        <div className="row gx-0">
          <h5 className="text_nfts line_none fs-5">
            BUSD: <span className="hero_text">{busdBalance}</span>{" "}
          </h5>
          {isUserRegistered && isConnected ? (
            <h5 className="text_nfts fs-5 line_none">
              Coins: <span className="hero_text">{userPoints}</span>{" "}
            </h5>
          ) : (
            <h5>
              <span className="color">Coins:</span> 0
            </h5>
          )}
          <div className="text-center justify-content-center d-lg-flex d-block align-items-center text-white mb-4">
            <h5 className="text-center mb-0">User Referral:</h5>
            <div className="d-flex align-items-center">
              <p className="text-center hero_text2 mb-0 ms-1 text-truncate mobile_text">
                {`https://xpacecoin.com/referral/${refLink}`}
              </p>
              <MdContentCopy
                className="copy-icon ms-2"
                onClick={copyToClipboard}
                style={{ cursor: "pointer" }}
              />
              {copySuccess && <span className="ms-2">{copySuccess}</span>}
            </div>
          </div>
          {options.map((option) => {
            return (
              <PoolItem
                key={option.id}
                {...option}
                busdBalance={busdBalance}
                userPoints={userPoints}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Pool;
